import React from 'react'

import SEO from 'components/SEO'
import ContactContent from 'components/Contact'

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      <ContactContent />
    </>
  )
}

export default Contact
