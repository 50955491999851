import React from 'react'
import PropTypes from 'prop-types'

const Intro = ({ pageTitle, imgSrc }) => {
  return (
    <div className="relative">
      <h1 className="text-vividBlue text-4xl md:text-5xl lg:text-6xl font-bold">{pageTitle}</h1>
      <div className="absolute right-0 top-0 max-w-480px z-0">
        <img src={imgSrc} alt={`${pageTitle} icon`} />
      </div>
    </div>
  )
}

Intro.propTypes = {
  pageTitle: PropTypes.string
}

export default Intro
