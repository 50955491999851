import { useStaticQuery, graphql } from 'gatsby'

export const usePageIconsImages = () => {
  const images = useStaticQuery(graphql`
    fragment pageIconImage on File {
      childImageSharp {
        gatsbyImageData
      }
    }
    query {
      aboutIcon: file(relativePath: { eq: "page-icons/about-icon.png" }) {
        ...pageIconImage
      }
      leadershipIcon: file(relativePath: { eq: "page-icons/leadership-icon.png" }) {
        ...pageIconImage
      }
      researchIcon: file(relativePath: { eq: "page-icons/research-icon.png" }) {
        ...pageIconImage
      }
      servicesIcon: file(relativePath: { eq: "page-icons/services-icon.png" }) {
        ...pageIconImage
      }
      publicIcon: file(relativePath: { eq: "page-icons/publication-icon.png" }) {
        ...pageIconImage
      }
      newsIcon: file(relativePath: { eq: "page-icons/news-icon.png" }) {
        ...pageIconImage
      }
      partnersIcon: file(relativePath: { eq: "page-icons/partners-icon.png" }) {
        ...pageIconImage
      }
      mediaIcon: file(relativePath: { eq: "page-icons/media-icon.png" }) {
        ...pageIconImage
      }
      databaseIcon: file(relativePath: { eq: "page-icons/database-icon.png" }) {
        ...pageIconImage
      }
      contactIcon: file(relativePath: { eq: "page-icons/contact-icon.png" }) {
        ...pageIconImage
      }
    }
  `)

  return images
}
