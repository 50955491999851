import React from 'react'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'

import Intro from 'elements/Intro'
import { StaticImage } from 'gatsby-plugin-image'
const ContactContent = () => {
  const {
    contactIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()
  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="Contact" imgSrc={src} />
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-16 gap-10">
          <div className="relative">
            <div className="flex items-center">
              <div className="rounded-full overflow-hidden">
                <StaticImage
                  src="../../images/mato_njavro.png"
                  className="rounded-full w-16 h-16"
                  alt="Dr. Mato Njavro"
                />
              </div>
              <span className="text-lg ml-5">Dr. Mato Njavro (贺敢)</span>
            </div>
            <ul className="flex text-sm mt-4 flex-col sm:flex-row">
              <li className="mr-4 mb-2 sm:mb-0">
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="mailto:mato.njavro@zsem.hr"
                >
                  email
                </a>
              </li>
              <li className="mr-4 mb-2 sm:mb-0">
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="https://www.linkedin.com/in/mato-njavro-0541877/"
                  target="_blank"
                >
                  Linkedin
                </a>
              </li>
              <li className="mr-4 mb-2 sm:mb-0">
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="https://www.facebook.com/njavro"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="https://twitter.com/njavro"
                  target="_blank"
                >
                  Twitter
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <span>
                WeChat ID: <strong>wxid_ay91gi0z9npq22</strong>
              </span>
            </div>
            <div className="flex flex-col">
              <span>WeChat QR Code:</span>
              <StaticImage src="../../images/mn-qr-code.jpg" className="w-24" alt="QR Code" />
            </div>
          </div>
          <div className="relative">
            <div className="flex items-center">
              <div className="rounded-full overflow-hidden">
                <StaticImage
                  src="../../images/zvonimir_stopic.png"
                  className="rounded-full w-16 h-16"
                  alt="Dr. Zvonimir Stopić"
                />
              </div>
              <span className="text-lg ml-5">Dr. Zvonimir Stopić (左立明)</span>
            </div>
            <ul className="flex text-sm mt-4 flex-col sm:flex-row">
              <li className="mr-4 mb-2 sm:mb-0">
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="mailto:zstopic@zsem.hr"
                >
                  email 1
                </a>
              </li>
              <li className="mr-4 mb-2 sm:mb-0">
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="mailto:z_nanook@yahoo.com"
                >
                  email 2
                </a>
              </li>
              <li className="mr-4 mb-2 sm:mb-0">
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="https://www.linkedin.com/in/zvonimir-stopić-a1160249/"
                  target="_blank"
                >
                  Linkedin
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <span>
                WeChat ID: <strong>z_nanook</strong>
              </span>
            </div>
            <div className="flex flex-col">
              <span>WeChat QR Code:</span>
              <StaticImage src="../../images/zs-qr-code.jpg" className="w-24" alt="QR Code" />
            </div>
          </div>

          <div>
            <div className="flex items-center">
              <div className="rounded-full overflow-hidden">
                <StaticImage
                  src="../../images/ivica_bakota.png"
                  className="rounded-full w-16 h-16"
                  alt="Dr. Ivica Bakota"
                />
              </div>
              <span className="text-lg ml-5">Dr. Ivica Bakota (白伊维)</span>
            </div>
            <ul className="flex text-sm mt-4">
              <li className="mr-4 mb-2 sm:mb-0">
                <a
                  className="inline-flex self-start text-white bg-vividBlue px-5 py-1  rounded-lg transform transition duration-300 ease-in-out hover:-translate-y-1"
                  href="mailto:bakotaivic@gmail.com"
                >
                  email
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <span>
                WeChat ID: <strong>IvicaIvica</strong>
              </span>
            </div>
            <div className="flex flex-col">
              <span>WeChat QR Code:</span>
              <StaticImage src="../../images/ib-qr-code.jpg" className="w-24" alt="QR Code" />
            </div>
          </div>
        </div>

        <div className="mt-32 flex flex-col">
          <span className="text-sm text-vividBlue font-bold mb-4">Address:</span>
          <span className="text-sm text-black font-bold mb-4">
            Zagreb School of Economics and Management,
            <br />
            Vukasovićeva ulica 1, 10,000 Zagreb
          </span>

          <div className="inline-flex">
            <a
              className="map relative h-full"
              href="https://www.google.com/maps/place/Zagreba%C4%8Dka+%C5%A1kola+ekonomije+i+managementa/@45.8145507,15.9458168,17.58z/data=!4m12!1m6!3m5!1s0x0:0x55f5ee4672128f5b!2sZagreba%C4%8Dka+%C5%A1kola+ekonomije+i+managementa!8m2!3d45.8144914!4d15.9458725!3m4!1s0x0:0x55f5ee4672128f5b!8m2!3d45.8144914!4d15.9458725"
              target="_blank"
            >
              <svg
                className="w-16 absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 z-1 opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                />
              </svg>
              <StaticImage
                className="rounded-lg max-w-xl"
                src="../../images/google-map.png"
                alt="Vukasovićeva ulica 1, 10,000 Zagreb"
                quality="100"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactContent
